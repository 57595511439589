















































































































import { Vue, Component } from 'vue-property-decorator';
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import {SwiperOptions} from "swiper";
import {AxiosResponse} from "axios";
const Axios = require('axios')

@Component({
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class Home extends Vue {


  public planImage!: string | (string | null)[]
  public buildOptions: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 10
  }
  public loadComplete: boolean = false
  public estateList: any = []
  public currentComplex: any = []
  public currentComplexId!: number
  public currentHouseId: number = 0
  public currentHouse: any = []
  public currentApartList: any = []

  public activeComplex!: number
  public activeHouse!: number
  public activeTab: any = []
  public hasError: boolean = false
  public errorText: string = ''

  created(){
    if (this.$route.query.complex){
      this.currentComplexId = parseInt(<string>this.$route.query.complex)
      this.getEstate().finally(() => {
        if (this.estateList.filter((item: any) => item.complex_id === this.currentComplexId).length === 0){
          console.log('empty')
          window.parent.postMessage('empty', '*')
        }
      })
      if(this.$route.query.image){
        this.planImage = this.$route.query.image
      }
    } else {
      this.hasError = true
      this.errorText = 'Не указан id комплекса'
    }
  }

  showTab(id: number){
    this.currentApartList.forEach((item: any) => {
      if(item.rooms === id){
        item.show = !item.show;
      }
    })
  }

  searchApartmentsFromComplexID(id: number){
    return this.estateList.filter((item: any) => item.complex_id == id)
  }

  setPlanImage(id: number){
    let item = this.estateList.filter((item: any) => item.id === id)
    this.planImage = item[0].plan_image
    this.$forceUpdate()
  }


  async getEstate(){
    try {
      const res: AxiosResponse = await Axios.get('https://sknvek.ru/getestate.json')
      this.estateList = res.data
      await this.getComplex(this.currentComplexId)
    } catch (e){
      this.hasError = true
      this.errorText = 'Error receiving data, error in the console'
      console.error(e)
    }
  }

  async getComplex(complexId: number){
    try {
      const res: AxiosResponse = await Axios.get('https://sknvek.ru/getcomplex.json')
      let complex = res.data.complexes.filter((item: any) => item.id === complexId)
      this.currentComplex = complex[0]
      this.activeComplex = this.currentComplex.houses[0].id
      this.getHouses(this.activeComplex)
      this.loadComplete = true
      this.$forceUpdate()
    } catch (e) {
      this.hasError = true
      this.errorText = 'Error receiving data, error in the console'
      console.error(e)
    }
  }

  getApartments(houseId: number){
    this.activeHouse = houseId
    let apartments: any = this.estateList.filter((item: any) => item.geo_house_entrance === houseId && parseInt(item.parent_id) === this.currentHouseId)
    let rooms: any = []
    this.currentApartList = []
    for (let i = 0; i < apartments.length; i++){
      let item = rooms.filter((items: any) => items === apartments[i].estate_rooms)
      if (item.length === 0){
        rooms.push(apartments[i].estate_rooms)
      }
    }
    rooms = rooms.sort()
    for (let i = 0; i < rooms.length; i++){
      let apart = apartments.filter((items: any) => items.estate_rooms === rooms[i])
      this.currentApartList.push({
        rooms: rooms[i],
        show: false,
        apartment: apart
      })
    }
  }

  getRoomName(room: number){
    switch (room) {
      case 1:
        return 'Однокомнатные'
      case 2:
        return 'Двухкомнатные'
      case 3:
        return 'Трехкомнатные'
      case 4:
        return 'Четырехкомнатные'
      case 5:
        return 'Пятикомнатные'
      case 6:
        return 'Шестикомнатные'
    }
  }

  getHouses(houseId: number){
    this.currentHouseId = houseId
    this.activeComplex = houseId
    let apartments: any = this.estateList.filter((item: any) => parseInt(item.parent_id) === houseId)
    let duplicates: any = []
    for (let i = 0; i < apartments.length; i++) {
      let item = duplicates.filter((items: any) => items === apartments[i].geo_house_entrance)
      if (item.length === 0){
        duplicates.push(apartments[i].geo_house_entrance)
      }
    }
    this.currentHouse = duplicates.sort()
    this.activeHouse = duplicates[0]
    this.getApartments(this.activeHouse)
  }

}
